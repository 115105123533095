import { TemplateComponentsArray } from '../../../../shared/typings/template';
import {
  ButtonType,
  TemplateButtonComponentTypes,
  TemplateComponentFormat,
} from '../../../templates/v1/typings';

export interface BotData {
  name: string;
  state: string;
  type: string;
}

export enum BotStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ALL = 'ALL',
}

export enum BotType {
  ORDER_BOT = 'ORDER_BOT',
  WELCOME_BOT = 'WELCOME_BOT',
  STOP_BOT = 'STOP_BOT',
}

export interface BotFlowPagination {
  hasMore: boolean;
  botCount: number;
  lastBotId: string;
  lastSortById: string;
}

interface Variable {
  text: string;
  type: string;
}

interface TemplateMessage {
  id: number;
  variables: Variable[];
}

interface Message {
  messageType: string;
  templateMessage: TemplateMessage;
}

export interface TemplateMessageStructure {
  id: number;
  dateCreated: Date;
  dateUpdated: Date;
  remoteId: string;
  integrationId: string;
  name: string;
  language: string;
  components: TemplateComponentsArray;
  category: string;
  status: string;
  type: string;
  dateDeleted?: any;
  rejectedReason: string;
  createdBy: string;
}

export interface Action {
  id: string;
  message: Message;
  messageStructure: TemplateMessageStructure | TextMessageStructure;
  replies: any[];
}

export interface TextMessageStructure {
  messageType: string;
  text: string;
}

export interface OrderBotMessage {
  header: string;
  catalogues: { id: string; name: string }[];
  footer: string;
}

export enum BotTriggerTypes {
  ORDER_BOOKING = 'ORDER_BOOKING',
  FIRST_MESSAGE = 'RECEIVED_FIRST_MESSAGE_IN_24_HOUR',
  STOP_MESSAGE = 'STOP_MESSAGE',
}

export interface BotTriggersData {
  id: string;
  trigger: BotTriggerTypes.FIRST_MESSAGE | BotTriggerTypes.STOP_MESSAGE;
  description: string;
  updatedBy: string;
  actionType: ActionTypes | null;
  actions: Action[];
}

interface OrderBotAction {
  id: string;
  message: {
    messageType: 'text';
    text: string;
  };
  messageStructure: {
    catalogues: { id: string; name: string }[];
    footer: string;
    header: string;
  };
  replies: any[];
}

export interface OrderBotTriggersData {
  id: string;
  trigger: BotTriggerTypes.ORDER_BOOKING;
  description: string;
  updatedBy: string;
  keywords: Array<{ id: string | null; reply: string }>;
  actionType: ActionTypes | null;
  actions: OrderBotAction[];
}

export enum ActionTypes {
  TEXT = 'TEXT',
  TEMPLATE = 'TEMPLATE',
}

export interface BotDetails {
  id: string;
  name: string;
  status: BotStatus;
  triggers: BotTriggersData[] | OrderBotTriggersData[];
  type: BotType;
  integrationId: string;
  shareAsLink?: boolean;
}

export interface TemplateSendData {
  messageType: string;
  templateMessage: {
    id: number | string;
    variables: { [key: string]: string }[];
  };
}

export interface TemplateMessageRender {
  header?: string;
  body: string;
  footer?: string;
  buttonType: ButtonType;
  buttonsData?: {
    [key: string]: {
      buttonText: string;
      value: string;
      type: TemplateButtonComponentTypes;
    }[];
  };
  type: TemplateComponentFormat | undefined;
}

export interface Integration {
  name: string;
  integrationId: string;
}

export interface BotFlowData {
  dateCreated: Date;
  dateUpdated: Date;
  id: string;
  integrationId: string | null;
  orgId: string;
  name: string;
  status: BotStatus;
  runs: number;
  integrationIds: Integration[];
  createdBy: string;
  lastUpdatedBy: string | null;
  createdByDtUser?: string;
  integrationIdsNames?: Integration[];
  lastRunDate: string;
  failedRunCount: number;
}

export interface CatalogueProduct {
  readonly productId: string;
  readonly position: number;
  readonly pictureUrl: string;
}

export interface Catalogue {
  readonly id: string;
  readonly title: string | null;
  readonly products: CatalogueProduct[] | null;
}

export interface CataloguesListData {
  readonly catalogues: Catalogue[];
}

export enum CatalogueShareSetting {
  SHARE_CATALOGUE_LINK = 'SHARE_CATALOGUE_LINK',
  SHARE_PRODUCT_PHOTOS = 'SHARE_PRODUCT_PHOTOS',
}

export enum LogTimeFilter {
  ALL = 'all',
  MONTH = 'month',
  WEEK = 'week',
  DAY = '24hr',
  HALF_DAY = '12hr',
  HOUR = '1hr',
}

export enum RunStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum RunType {
  ACTION = 'ACTION',
  TRIGGER = 'TRIGGER',
}

export interface BotRunLogs {
  botId: string;
  runId: number;
  logId: number;
  status: RunStatus;
  name: string;
  type: RunType;
  details: Record<
    string,
    string | number | boolean | Record<string, string | number | boolean>
  >;
  executionDuration: number;
  executionTime: string;
  wabaName?: string;
  wabaPhone?: string;
  dateCreated: string;
  dateUpdated: string;
}

export enum RunState {
  ALL = 'ALL',
  STOPPED = 'STOPPED',
  COMPLETED = 'COMPLETED',
  RUNNING = 'RUNNING',
  ERRORED = 'ERRORED',
}

export interface BotRuns {
  botId: string;
  id: number;
  customerId: string;
  displayName: string;
  dateCreated: string;
  dateUpdated: string;
  logs: BotRunLogs[];
  state: RunState;
  name: string;
  status: BotStatus;
}

export interface BotRunsPaginationData {
  hasMore: boolean;
  lastRun: number | null;
}

export interface BotRunsData extends BotRunsPaginationData {
  data: BotRuns[];
}

// adadad

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortFields {
  wabaName = 'wabaName',
  wabaDisplayName = 'wabaDisplayName',
  dateUpdated = 'dateUpdated',
  status = 'status',
}

export type BotSortFilter = {
  sortBy: SortFields;
  direction: SortDirection;
};

export type GetBotByTypeRequest = {
  sortFilter?: BotSortFilter;
  searchFilter?: string;
  lastBotId?: string;
  limit?: number;
  hasMore: boolean;
};

export type GetBotByTypeResponse = {
  data: Array<BotTypeData>;
  hasMore: boolean;
  lastBotId: string;
  botName: string;
  triggers: BotTriggersData[] | OrderBotTriggersData[];
};

export type BotTypeData = {
  wabaName: string;
  wabaPhone: string;
  wabaDisplayName: string;
  shareAsLink?: boolean;
  type: string;
  id: string;
  dateUpdated: string;
  status: BotStatus;
};

export interface SwitchStatusRequest {
  botIds: string[];
  status: BotStatus;
}

export interface BotValid {
  id: string;
  status: BotStatus;
}

export interface SwitchStatusResponse {
  validUpdates: BotValid[];
  invalidBotIds: string[];
}

export enum FilterActionTypes {
  SEARCH = 'SEARCH',
  DATE = 'DATE',
  NAME = 'NAME',
  DISPLAY_NAME = 'DISPLAY_NAME',
  STATUS = 'STATUS',
}

export interface SearchAction {
  type: FilterActionTypes.SEARCH;
  payload: string;
}

export interface OtherActions {
  type:
    | FilterActionTypes.DATE
    | FilterActionTypes.NAME
    | FilterActionTypes.DISPLAY_NAME
    | FilterActionTypes.STATUS;
}

export type ActionType = SearchAction | OtherActions;

export interface BotInitialData {
  botName: string;
  triggers: BotTriggersData[] | OrderBotTriggersData[];
  status: BotStatus;
}

export interface UpdateActionMultipleResponse {
  validUpdates: string[];
  invalidBots: string[];
  trigger: BotTriggersData[] | OrderBotTriggersData[];
}
