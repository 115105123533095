export enum SortOptionsEnum {
  NEWEST_FIRST = 'NEWEST_FIRST',
  OLDEST_FIRST = 'OLDEST_FIRST',
  A_TO_Z = 'A_TO_Z',
  Z_TO_A = 'Z_TO_A',
  MOST_RUNS = 'MOST_RUNS',
  FEWEST_RUNS = 'FEWEST_RUNS',
  RECENT_RUNS = 'RECENT_RUNS',
  CREATED_BY_A_TO_Z = 'CREATED_BY_A_TO_Z',
  CREATED_BY_Z_TO_A = 'CREATED_BY_Z_TO_A',
}

export interface BotMetaType {
  agents: AgentData[];
  wabas: WabaData[];
  botCount: number;
}

export interface AgentData {
  id: string;
  name: string;
}

export interface WabaData {
  id: string;
  name: string;
  dtPhone: string;
}

export enum BotFilterEnum {
  ALL_AGENTS = 'ALL_AGENTS',
  ALL_WABAS = 'ALL_INTEGRATIONS',
}
