/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { getI18N } from '../../i18N';

export const QUERY_PARAM_BASE_KEY = 'trapKey';

export const TEAM_MEMBER = 'TEAM_MEMBER';

export const DEFAULT_TEAM = 'DEFAULT_TEAM';

export const ALL_TEAMS = 'dt_all_teams';

export const DEFAULT_MEMBER = 'DEFAULT_MEMBER';

export const ALL_MEMBERS = 'dt_all_agents';

export const DEFAULT_INTEGRATION = 'DEFAULT_INTEGRATION';

export const ALL_INTEGRATIONS = 'TEMP_INTEGRATION_ID';

export const DEFAULT_WIDGET = 'DEFAULT_WIDGET';

export const ALL_BOTS = 'dt_all_bots';

export const getLoadingText = () => {
  const { t } = getI18N();
  return t('loading');
};
export const MUI_LICENCE_KEY =
  'e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y';

export const TAGIFY_REGEX = /{{.+?}}$/g;

export const NAME_REGEX = /^[a-zA-Z0-9\s]+$/;

export const PHONE_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

export const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const EMOJI_REGEX =
  /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]|[\u2700-\u27BF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/gm;

export const TEMPLATE_BUTTON_SPECIAL_CHARACTERS_REGEX = /[*~`_]/;

export const UNICODE_ZERO_WIDTH_REGEX = /[\u200B-\u200D\uFEFF]/g;

export const TEMPLATE_NAME_REGEX = /^[_a-z0-9 ]+$/;

export const TEMPLATE_VERSION_REGEX = /_v\d+$/;

export const URL_REGEX =
  /^(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#*!;()-]*[\w@?^=%&\/~+#-])$/;

export const GST_REGEX =
  /^(0[1-9]|[1-2][0-9]|3[0-8]|9[9|7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z][1-9a-zA-Z][zZ][0-9a-zA-Z])+$/;

export const VARIABLE_REGEX = new RegExp(/{{.+?}}/, 'g');

export const VARIABLE_DEFAULT_LENGTH = 10;

export const PARSE_VALUE_TAGIFY_REGEX = /\[\[(.*?)\]\]/g;

export const VIDEO_FOR_HEALTHY_ECOSYSTEM = 'https://www.youtube.com/watch?v=vlonw772Ll0'

export const chatRowAvatarSize = 42;

export const chatRowAvatarStyles = css({
  width: `${chatRowAvatarSize}px`,
  height: `${chatRowAvatarSize}px`,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '4px',
});
