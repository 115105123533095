import { atom } from 'jotai';
import {
  createPaginatedRequestAtom,
  createPaginatedRequestFamily,
  createRequestAtom,
  createRequestFamily,
} from '../../../../shared/utils/request-atom';
import {
  BotDetails,
  BotFlowData,
  CataloguesListData,
  BotRuns,
  BotRunsPaginationData,
  BotData,
  BotTypeData,
  GetBotByTypeRequest,
  BotInitialData,
  BotFlowPagination,
  BotStatus,
} from '../typings';
import { atomFamily } from 'jotai/utils';
import { FilterProps } from '../components/bots-list';
import { SortOptionsEnum } from '../components/bots-list/types';

export const botDataListAtom = createRequestAtom<BotData[]>();

export const botFlowListAtom = createRequestAtom<BotFlowData[]>();

export const botFlowListPaginatedAtom = createPaginatedRequestAtom<
  BotFlowData,
  BotFlowPagination
>({
  loading: false,
});

export const botsTriggerDataFamily = createRequestFamily<BotDetails>();

export const botFilterAtom = atom<FilterProps>(
  localStorage['BOT_LIST_FILTER']
    ? (JSON.parse(localStorage['BOT_LIST_FILTER']) as FilterProps)
    : {
        agentSelected: ['ALL_AGENTS'],
        wabaSelected: ['ALL_INTEGRATIONS'],
        statusSelected: BotStatus.ALL,
        sortSelected: SortOptionsEnum.NEWEST_FIRST,
        searchQuery: '',
      }
);

export const getCataloguesListRequestAtom =
  createRequestAtom<CataloguesListData>();

export const botRunsAtom = createPaginatedRequestAtom<
  BotRuns,
  BotRunsPaginationData
>({
  loading: false,
});

export const botTypeAtomFamily = createPaginatedRequestFamily<
  BotTypeData,
  GetBotByTypeRequest
>({
  loading: false,
});

export const botTypeInitialDataAtom = atomFamily(
  () => {
    return atom<BotInitialData | undefined>(undefined);
  },
  (
    a: {
      id: string;
    },
    b: {
      id: string;
    }
  ) => a.id === b.id
);

export const openUpgradePlanForBotStudioModalAtom = atom<boolean>(false)
